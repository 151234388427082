<template>
  <div>
    <b-row>
      <b-col md="12">
        <Breadcrumb>
          <template #col-right>
            <div class="d-flex justify-content-end">
              <FilterDays
                :listagemFiltros.sync="listagemFiltros"
                :urlResourcesLoading.sync="urlResourcesLoading"
                @refreshResourcesLoading="getResourcesLoading"
                :hasMinDate="true"
              />
            </div>
          </template>
        </Breadcrumb>
      </b-col>
      <b-col class="resource-loading-filtro" md="12" v-if="!isEmpty(listagemFiltros.workcenter) || !isEmpty(listagemFiltros.resource) || (listagemFiltros.date.dataInicio)">
        <button type="button" @click="clearFilters" class="limpar-filtros-btn d-none d-sm-block">{{$t('ResourceLoading.Clean')}}</button>
        <div class="filtros-area">
          <div class="filtro-item" v-if="listagemFiltros.date && listagemFiltros.date.dataInicio && listagemFiltros.date.dataFim" >
            {{ getFormattedDate() }}
            <imgDeleteIcon @click="deleteFilteredItem('date')" />
          </div>
          <div class="filtro-item" v-for="workcenter in listagemFiltros.workcenter" :key="'workcenter-filter-item' + workcenter.id">
            {{workcenter.name}}<imgDeleteIcon @click="deleteFilteredItem('workcenter', workcenter.id)" />
          </div>
          <div class="filtro-item"  v-for="resource in listagemFiltros.resource" :key="'resource-filter-item' + resource.id">
            {{resource.name}}<imgDeleteIcon @click="deleteFilteredItem('resource', resource.id)" />
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="4" sm="6" class="card-resource-loading resource-loading-late">
        <b-card :title="$t('ResourceLoading.ForecastLateOrdes')">
          <div class="container-percent">
            <div class="percent-total-orders">
              <vc-donut
              :size="116"
              unit="px"
              :thickness="13"
              foreground="#F4EEE8"
              :sections="[{ value: resourcesList.forecast_orders_late[0].percent_forecast_late_orders * 100, color: '#FF8D2E'  }]">{{(resourcesList.forecast_orders_late[0].percent_forecast_late_orders * 100).toFixed(0)}}%</vc-donut>
            </div>
            <div>
              <div class="d-flex justify-content-between data-resource-loading-late">
                <div>
                  <span>{{$t('ResourceLoading.Late')}}</span>
                  <h3>{{resourcesList.forecast_orders_late[0].forecast_late_orders}}</h3>
                </div>
                <div>
                  <span>{{$t('ResourceLoading.Total')}}</span>
                  <h3>{{resourcesList.forecast_orders_late[0].total_orders}}</h3>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col lg="4" sm="6" class="card-resource-loading schedule-operation-quantity">
        <b-card :title="$t('ResourceLoading.ScheduleOperationQuantity')">
        <div class="operations-area">

          <b-row class="d-flex justify-content-center operations">
            <div class="operation-line" v-for="(item, index) in resourcesList.operations_by_resources" :key="'resources'+index">
                <div class="operation-name">{{item.resource_name}}</div>
                <div class="operation-border"></div>
                <div class="operation-quantity">{{item.quantity_operations}}</div>
            </div>
          </b-row>
        </div>
        </b-card>
      </b-col>
      <b-col lg="4" sm="12" class="card-resource-loading current-resource-status">
        <b-card :title="$t('ResourceLoading.CurrentResourceStatus')">
          <div class="total-operations">{{`${this.totalOperations} ${$t('ResourceLoading.OperationsUpper')}`}} </div>
          <div class="colored-bar">
            <div v-for="(item, index) in resourcesList.operations_by_status" :style="{'--customColor': item.color, width: `${item.percent *100}%`}" :key="'color-line-'+index"></div>
          </div>
          <div class="explain-operation-quantity">
            <b-row>
                <b-col md="6" sm="3" v-for="(item, index) in resourcesList.operations_by_status" class="explain-operation col-4" :key="'color-explain-'+index"  >
                  <div class="color-line" :style="{'--customColor': item.color}"></div>
                  <div class="explain-text">{{item.status_name}}</div>
                  <div class="color-percentage">{{(item.percent *100).toFixed(2)}}%</div>
                </b-col>
            </b-row>

          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-for="(centro, index) in resourcesList.operations_by_workcenter" :key="'centro-' + index"
      :class="[
      'centro-trabalho',
      `${index == 0 && 'first-centro-trabalho'}`,
      ]" md="12">
        <button type="button" class="w-100 collapse-centro-trabalho-btn"  v-b-toggle="`collapse-${index}`">
            <div class="centro-name">
              <imgArrowCollapse /><div>{{centro.workcenter_name}}</div>
            </div>
            <div class="centro-operacoes">
              {{centro.quantity_operations}} {{$t("ResourceLoading.OperationsUpper")}}
            </div>
        </button>
        <b-collapse class="collapse-table" :id="'collapse-'+index">
              <b-table
                :items="resourcesList.operations_by_resources.filter(item => item.workcenter_id == centro.workcenter_id)"
                :fields="colunas"
                >
                <template v-slot:head(resource_name)="data">
                  <div class="th-centro"><img-robot-hand /> {{data.label}}</div>
                </template>

                <template v-slot:head(quantity_operations)="data">
                  <div class="th-centro"><img-settings /> {{data.label}}</div>
                </template>
                <template v-slot:head(available_time)="data">
                  <div class="th-centro"><img-reload-watch /> {{data.label}}</div>
                </template>
                <template v-slot:head(working_time)="data">
                  <div class="th-centro"><img-reload24 /> {{data.label}}</div>
                </template>
                <template v-slot:head(idle_time)="data">
                  <div class="th-centro"><img-reload-check /> {{data.label}}</div>
                </template>
                <template v-slot:head(percent_forecast_late_operations)="data">
                  <div class="th-centro"><img-checklist-watch /> {{data.label}}</div>
                </template>
                <template v-slot:cell(quantity_operations)="data">
                  <div class="operacoes-td">{{data.value}}
                    <b-dropdown boundary="body" :id="'dropdown-'+data.key" >
                      <template #button-content>
                        <div class="check-btn"><img-arrow-down /></div>
                      </template>
                      <div class="select-title">{{$t('ResourceLoading.OccupationOperation')}}</div>
                      <b-dropdown-item v-for="(item, index) in data.item.operations" class="select-operation-item" :key="'percentage-select-item-'+index">
                        <div class="select-operation-item-name">{{item.name}}</div>
                        <div class="select-operation-item-graph">
                          <div class="select-operation-item-value">{{(item.percent_working_time_by_resource *100).toFixed(0)}}%</div>
                          <div class="select-operation-graph">
                            <vc-donut
                              :size="30"
                              unit="px"
                              :thickness="20"
                              foreground="#F4EEE8"
                              :sections="[{ value: item.percent_working_time_by_resource  * 100, color: '#FF8D2E'  }]">
                            </vc-donut>
                          </div>

                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
                <template v-slot:cell(available_time)="data">
                  <div>{{parseInt(data.value) / 10 >= 1 ? parseInt(data.value) : '0'+parseInt(data.value)}} h</div>
                </template>
                <template v-slot:cell(working_time)="data">
                  <div>{{parseInt(data.value) / 10 >= 1 ? parseInt(data.value) : '0'+parseInt(data.value)}} h</div>
                </template>
                <template v-slot:cell(idle_time)="data">
                  <div>{{parseInt(data.value) / 10 >= 1 ? parseInt(data.value) : '0'+parseInt(data.value)}} h</div>
                </template>
                <template v-slot:cell(percent_forecast_late_operations)="data">
                  <div class="centro-resorce-percent">
                    <vc-donut
                      :size="53"
                      unit="px"
                      :thickness="9"
                      foreground="#F4EEE8"
                      :sections="[{ value: data.value * 100, color: '#FF8D2E'  }]">{{(data.value * 100).toFixed(0)}}%
                    </vc-donut>
                  </div>
                </template>
              </b-table>
          </b-collapse>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import imgDeleteIcon from '@/assets/images/icons/delete-icon.svg'
import imgArrowDown from '@/assets/images/pages/arrow-down.svg'
import imgArrowCollapse from '@/assets/images/pages/arrow-collapse.svg'
import imgRobotHand from '@/assets/images/pages/robot-hand-icon.svg'
import imgSettings from '@/assets/images/pages/settings-icon.svg'
import imgReloadCheck from '@/assets/images/pages/reload-check.svg'
import imgReloadWatch from '@/assets/images/pages/reload-watch-icon.svg'
import imgReload24 from '@/assets/images/pages/reload-24-icon.svg'
import imgChecklistWatch from '@/assets/images/pages/checklist-watch.svg'
import moment from "moment";
import { mapMutations } from "vuex";
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BButton,
  BCollapse,
  VBToggle,
  BDropdown,
  BDropdownItem,
  } from 'bootstrap-vue'
import Breadcrumb from '../../../layouts/components/Breadcrumb.vue'
import FilterDays from '../../../@core/components/filter-days/FilterDays.vue'

export default {
  components: {
    imgArrowDown,
    imgArrowCollapse,
    imgRobotHand,
    imgSettings,
    imgReloadCheck,
    imgReloadWatch,
    imgReload24,
    imgChecklistWatch,
    BCard,
    BTable,
    BRow,
    BCol,
    imgDeleteIcon,
    BButton,
    BCollapse,
    VBToggle,
    BDropdown,
    BDropdownItem,
    Breadcrumb,
    FilterDays,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data(){
    return {
      totalOperations:'',
      resourcesList:[],
      colunas: [
        {
          key: "resource_name",
          label: this.$t("FilterDays.Resources"),
        },
        {
          key: "quantity_operations",
          label: this.$t("FilterDays.Operations"),
        },
        {
          key: "available_time",
          label: this.$t("FilterDays.AvailableTime"),
        },
        {
          key: "working_time",
          label: this.$t("FilterDays.BusyTime"),
        },
        {
          key: "idle_time",
          label: this.$t("FilterDays.FreeTime"),
        },
        {
          key: "percent_forecast_late_operations",
          label: this.$t("FilterDays.ForecastDelay"),
        },
      ],
      renderCentros: true,
      listagemFiltros: {
        workcenter: [],
        resource: [],
        date: {
          dataInicio: '',
          dataFim: ''
        }
      },
      urlResourcesLoading:'',
      prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
    }
  },
  created(){
    this.currentSite = JSON.parse(localStorage.getItem('currentSite'));
    this.getResourcesLoading();
  },

  computed: {
    percentagesOperationStatus() {
      return [this.getOperationPercentage(this.percentageSetup), this.getOperationPercentage(this.percentageRunning), this.getOperationPercentage(this.percentageStopped), this.getOperationPercentage(this.percentageConcluded)]
    },
    isScrollBar(){
      var element = document.getElementById('collapse-0');
        return element.scrollWidth > element.clientWidth
    }
  },
  methods: {
    ...mapMutations('app', [
      'UPDATE_FLAG_SHOW_OVERLAY'
    ]),
     getResourcesLoading(){
      this.UPDATE_FLAG_SHOW_OVERLAY(true);
      this.$http.get(`/api/${this.prefix}/order/resources-loading?site_guid=${this.currentSite.guid}`+ this.urlResourcesLoading)
        .then(response => {
          this.resourcesList = response.data.response;
          this.totalOperations = response.data.response.operations_by_status.map(item => parseInt(item.quantity)).reduce((prev, curr) => prev + curr, 0);
          this.UPDATE_FLAG_SHOW_OVERLAY(false);
        })
        .catch(error => {
          console.log(error)
        })
    },
    clearFilters() {
      this.listagemFiltros = {
        workcenter: [],
        resource: [],
        date: {
          dataInicio: '',
          dataFim: ''
        }
      }
      this.verificaURL()
    },
    verificaURL(){
            this.urlResourcesLoading =  `&filterDays=7`

            if (this.listagemFiltros.workcenter.length > 0){

                this.urlResourcesLoading += `&workcenters_ids=${this.listagemFiltros.workcenter.map(item => item.id)}`
            }
            if (this.listagemFiltros.resource.length > 0){
                this.urlResourcesLoading += `&resources_ids=${this.listagemFiltros.resource.map(item => item.id)}`
            }
            if(this.listagemFiltros.date.dataInicio){
                this.urlResourcesLoading =  ''
                this.urlResourcesLoading += `&start_date=${this.listagemFiltros.date.dataInicio}`
            }
            if(this.listagemFiltros.date.dataFim){
                this.urlResourcesLoading =  ''
                this.urlResourcesLoading += `&end_date=${this.listagemFiltros.date.dataFim}`
            }
            this.getResourcesLoading()
        },


    isEmpty(obj) { // for ES3 and older
      for(var prop in obj) {
          if(obj.hasOwnProperty(prop))
              return false;
      }
      return true;
    },
    formatDate(dateTime){
      if (dateTime){
        return dateTime.substring(0,10);
      }
      return
    },
    deleteFilteredItem(type = null, id = null) {
      if(type == 'workcenter') {
        this.listagemFiltros.workcenter.splice(
          this.listagemFiltros.workcenter.indexOf(
            this.listagemFiltros.workcenter.find((item) => item.id == id)), 1)
      } else if(type == 'resource') {
        this.listagemFiltros.resource.splice(
          this.listagemFiltros.resource.indexOf(
            this.listagemFiltros.resource.find((item) => item.id == id)), 1)
      } else {
        this.listagemFiltros.date.dataInicio = ''
        this.listagemFiltros.date.dataFim = ''
      }
      this.verificaURL()
    },
    getFormattedDate() {
      let formatedDate = '';

      if (localStorage.getItem('language') == 'pt-BR')
          formatedDate = `${moment(this.listagemFiltros.date.dataInicio).format('DD/MM/YY')} ${this.$t('Operator.Until')} ${moment(this.listagemFiltros.date.dataFim).format('DD/MM/YY')}`;
      else if (localStorage.getItem('language') == 'en-US')
        formatedDate = `${moment(this.listagemFiltros.date.dataInicio).format('MM/DD/YY')} ${this.$t('Operator.Until')} ${moment(this.listagemFiltros.date.dataFim).format('MM/DD/YY')}`;

      return formatedDate;
    }
  },
}
</script>

<style lang="scss">
.overlay-mensage{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.explain-operation-quantity {
    &::before {
      display: none;
    }
  }

@media (max-width: 480px) {
  .col-filter-days{
    justify-content: center !important;
  }
  .limpar-filtros-btn{
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 768px )  {
  .explain-operation-quantity {
    &::before {
      display: none;
    }
  }

}

.tooltip-inner {
    padding: 0;
}
.resource-loading-filtro {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .limpar-filtros-btn {
    background: #974900;
    border-radius: 5px;
    white-space: nowrap;
    height: fit-content;
    border: 1px solid #974900;
    padding: 3px 14px;
    color: #fff;
    margin-right: 16px;
    font: 600 12px/20px initial;
    &:hover {
      background: #F7F3EF;
      color: #974900;
    }
  }
  .filtros-area {
    display: flex;
    overflow: auto;
    padding-right: 28px;
    margin-right: -28px;
    &::-webkit-scrollbar {
      height: 5px;               /* width of the entire scrollbar */
      border-radius: 50%;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #FBEEE8;        /* color of the tracking area */
      margin-right: 28px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #CFC4BE;    /* color of the scroll thumb */
      border-radius: 20px;       /* roundness of the scroll thumb */
    }
    .filtro-item {
      display: flex;
      font: 600 12px/20px initial;
      align-items: center;
      white-space: nowrap;
      justify-content: center;
      padding: 6px 14px;
      margin: 0 5px;
      background-color: #FFDBC4;
      color: #974900;
      border-radius: 8px;
      svg {
        margin-left: 12px;
      }
      &:hover {
          cursor: pointer;
        }
    }
  }
}



.col-filter-days{
  display: flex;
  justify-content: end;
}
.card-resource-loading {
  max-height: 264px;
  padding: 0 15px;
  margin-bottom: 35px;
  .card {
    height: 280px;
    .card-body {
      padding: 16px;
      .card-title {
        color: #4C4541;
        font: 600 18px/26px initial;
        margin-bottom: 7px;
      }
    }
  }
}

.percent-total-orders {
  margin-bottom: 30px;
  .cdc-text{
    font-weight: 600;
    font-size: 26px !important;
    line-height: 36px;
    color: #4C4541;
  }
}
.container-percent {
  display: flex;
  flex-direction: column;
}
.resource-loading-late {
  .card {
    .percent {
      margin-bottom: 7px;
    }
    .data-resource-loading-late {
      border-top: 1px solid #ECE0DB;
      margin: 0 -16px -16px -16px;
      & > div {
        width: 100%;
        text-align: center;
        padding: 16px;
        span {
          font: 400 14px/20px initial;
          color: #998F8A;
        }
        h3 {
          font: 600 16px/26px initial;
          color: #4C4541;
          margin: 4px 0 0 0;
        }
        &:first-child {
          border-right: 1px solid #ECE0DB;
        }
      }
    }
  }
}
.schedule-operation-quantity {
  padding: 0 15px;
  .card {
    overflow: hidden;
  }
  .card-body {
      padding: 16px;
      padding-right: 5px !important;
      padding-bottom: 0 !important;
    }
  .card-title {
    font: 600 18px/26px initial;
    margin-bottom: 6px !important;
    color: #4C4541;
  }
  .operations-area {
    height: 216px;
    overflow: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;               /* width of the entire scrollbar */
      border-radius: 50%;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #FBEEE8;        /* color of the tracking area */
      margin-bottom: 16px;
      margin-top: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #CFC4BE;    /* color of the scroll thumb */
      border-radius: 20px;       /* roundness of the scroll thumb */
    }
    .operations {
      display: flex;
      flex-direction: column;
      .operation-line {
        display: flex;
        align-items: center;
        padding: 6px 14px;
        margin-right: 16px;
        .operation-name {
          font: 400 14px/20px initial;
          margin-right: 10px;
          color: #998F8A;
        }
        .operation-border {
          flex: 1;
          height: 0px;
          border-top: 1px dashed #998F8A;
        }
        .operation-quantity {
          margin-left: 10px;
          font: 600 14px/20px initial;
          color: #4C4541;
        }
      }
    }
  }
}
.current-resource-status {
  padding: 0 15px;
  max-height: 264px;
  .card-body {
    .card-title {

      margin-bottom: 4px !important;
    }
    padding: 16px;
    .colored-bar {
      display: flex;
      border-radius: 16px;
      overflow-x: hidden;
      & > div {
        height: 10px;
        background-color: var(--customColor);
      }
    }
  }
  .total-operations {
    font: 600 14px/20px initial;
    color: #998F8A;
    margin-bottom: 4px;
  }
  .explain-operation-quantity {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // padding-left: 9px;
    // gap: 25px;
    position: relative;
    margin: 45.5px 0 33.5px 0;
    &::before {
      background-color: #F2F0F4;
      content: '';
      height: 1px;
      width: 100%;
      top: calc(50% - 13px);
      position: absolute;
    }
    .explain-operation {
      position: relative;
      margin-bottom: 25px;
      .explain-text {
        font: 400 12px/16px initial;
        color: #998F8A;
        margin-left: 9px;
      }
      .color-line {
        position: absolute;
        background-color: var(--customColor);
        width: 5px;
        height: 100%;
        border-radius: 10px;
      }
      .color-percentage {
        font: 600 12px/16px initial;
        color: #4C4541;
        margin-left: 9px;
      }
    }
  }
}
.centro-trabalho {
  margin-bottom: 16px;
  .collapse-table {
    overflow-x: auto;
    margin: 0 -16px;
    margin-bottom: -16px;
    padding: 0 16px;
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;               /* width of the entire scrollbar */
      border-radius: 50%;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #FBEEE8;        /* color of the tracking area */
      margin: 0 16px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #CFC4BE;    /* color of the scroll thumb */
      border-radius: 20px;       /* roundness of the scroll thumb */
    }
  }
  .collapse-centro-trabalho-btn {
    background: #F7F3EF;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border-radius: 6px;
    transition: all 0.2s;

    &.not-collapsed {
      background: #EBDFD3;
      .centro-name {
        svg {
          transform: rotate(90deg);
        }
      }
    }
    &:hover {
      background: #FFEDE2;
    }
    .centro-name {
      color: #4C4541;
      font: 600 14px/20px initial;
      padding: 8px 0px;
      display: flex;
      align-items: center;
      svg {
        transition: transform 0.2s;
        margin-right: 11.88px
      }
    }
    .centro-operacoes {
      font: 600 12px/16px initial;
      color: #9E9E9E;
      padding: 1px 9px;
      border-radius: 4px;
      background: #FAFAFA;
    }
  }
  table {
    min-width: 1156px !important;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 16px;
    margin-bottom: 0px;
    td, th {
      white-space: nowrap;
      width: 1%;
    }
    thead {
      th {
        text-transform: none;
        border: 0;
        padding: 0;
        background: transparent !important;
        div.th-centro {
          display: flex;
          align-items: center;
          padding: 0 16px;
          margin: -8px 0;
          font: 400 14px/20px initial;
          color: #998F8A;
          svg {

            margin-right: 4px;
          }
        }
      }
    }
    tbody {
      tr {
        margin: 10px;
        border-radius: 6px !important;
        overflow: hidden;
        width: 100%;
        padding: 16px !important;
        box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
        td {
          width: 1%;
          border: none;
          background: #fff;
          padding: 6px 16px;
          font: 400 14px/20px initial;
          color: #4C4541;
          &:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }
          &:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
          .operacoes-td {
            display: flex;
            align-items: center;
            .dropdown {
              .btn {
                padding: 0;
                border: none;
                background: none !important;
                width: fit-content;
                height: 13px;
                &:active,
                &:focus {
                  background: none !important;
                }
              }
              .dropdown-menu {
                height: 290px;
                width: 343px;
                padding: 0;
                overflow: auto;
                z-index: 100;
                border-right: 16px solid #fff;
                background: #fff;
                &::-webkit-scrollbar {
                  width: 5px;               /* width of the entire scrollbar */
                  border-radius: 50%;
                }

                &::-webkit-scrollbar-track {
                  border-radius: 10px;
                  background-color: #FBEEE8;        /* color of the tracking area */
                  margin: 16px;
                  margin-right: 16px;
                }

                &::-webkit-scrollbar-thumb {
                  background-color: #CFC4BE;    /* color of the scroll thumb */
                  border-radius: 20px;       /* roundness of the scroll thumb */
                }
                .select-title {
                  padding: 16px 16px 10px 16px;
                  color: #4C4541;
                  font: 600 14px/20px initial;
                }
                .select-operation-item {
                  .dropdown-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-right: 10px;
                  }
                }
                .select-operation-item-name {
                  color: #998F8A;
                }
                .select-operation-item-graph   {
                  display: flex;
                  align-items: center;

                  .select-operation-item-value {
                    margin-right: 10px;
                    color: #4C4541;
                  }

                }
              }
            }
            .check-btn {
              margin-left: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #974900;
              border-radius: 50%;
              width: 13px;
              height: 13px;

            }
          }
          .centro-resorce-percent {
            .cdc-container{
              justify-content: left;
              .cdc-text{
                font-weight: 400;
                font-size: 16px !important;
                line-height: 22px;
                color: #4C4541;
              }
            }

          }
        }
      }
    }
  }
}

.centro-trabalho{
  .collapse-table  {
    overflow-y: hidden;
    margin-bottom: 0;
    table {
      margin-bottom: -8px;
      min-height: calc(100% + 40px) !important;
    }
  }
}

</style>
